import React from "react";
import { Box, Toolbar, Typography } from "@mui/material";
import logo from "../images/logo.png";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineInstagram } from "react-icons/ai";
import { ImLinkedin } from "react-icons/im";
import { AiFillYoutube } from "react-icons/ai";
import { FaFacebookMessenger } from "react-icons/fa";
import { AiFillMediumSquare } from "react-icons/ai";
import {
  Grid,
} from "@mui/material"



const Links = [
  { href: "#home", text: "HOME" },

  { href: "#features", text: "FEATURES" },

  {
    href: "#spreetail",
    text: " SPREETAIL'S NETWORK"
  },
  {
    href: "#policies",
    text: "PRIVACY POLICIES"
  },
  {
    href: "#policies",
    text: "TERMS OF USE"
  },

];
function Footer() {
  const location = useLocation();
  return (
    <Box
      sx={{
        backgroundColor: "#050410",
        width: "100%",
        height: { xs: "200px", md: "160px" },
        padding: {md:"40px 40px",xs:"20px 20px"},
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "5px"
      }}
    >

      <Grid container sx={{ height: "80px" }}>
        <Grid item md={3}>
          <Toolbar sx={{ justifySelf: "start" }}>
            <Link
              to={"/"}
              style={{
                textDecoration: "none",
                color: "white",
                textTransform: "uppercase",
                fontWeight: "1000",
                fontSize: "1.7rem",
                fontFamily: "satoshi"
              }}
            >
              ECHO
            </Link>
          </Toolbar>
        </Grid>
        <Grid item md={9} xs={12}>
          <Grid container sx={{  height: "100%", width: "100%" }} gap={{md:2,xs:1}}>
            <Grid item xs={12} sx={{  paddingLeft: {md:"100px"}, paddingBottom: "0px", maxHeight: "40%" }}>
              {/* <Toolbar sx={{ gap: "40px", display: { xs: "none", md: "flex" },padding:"0px" }}> */}
              {Links.map((item, i) => (
                <a
                  style={{
                    textDecoration: "none",
                    fontSize: "14px",
                    fontWeight: "500",
                    // textTransform: "uppercase",
                    fontFamily: "satoshi",
                    color: "#ABBED4",
                    padding: "0px",
                    margin: "0px",
                    marginRight:"30px"

                  }}
                  href={item.href}
                  className={`"base_link"
                      `}
                >
                  {item.text}
                </a>
              ))}
              {/* </Toolbar> */}
            </Grid>
            <Grid item xs={12} sx={{  paddingLeft: {md:"100px"}, paddingBottom: "0px", maxHeight: "40%" }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "14px" },
                      textAlign: { xs: "center", lg: "left" },
                      fontWeight: "400",
                      textTransform: "capitalize",
                      color: "#ABBED4",
                      lineHeight: "20px",
                      letterSpacing: "1.5px",
                      marginBottom: "15px"
                    }}
                    className='satoshi'
                  >
                    © 2024 Echo. All rights reserved.
                  </Typography>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
